html,
body,
#root
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ivory;
}

/* Interface */

.interface
{

    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    width: 100%;
    height: 10%;
    user-select: none;
    /* font-family:  'Bebas Neue', cursive; */
}

.file_open{
    background-color: rgba(68, 68, 176, 0.25);
    margin: 10px;
    padding: 5px;

    border-radius: 15px;
    transition: 0.2s;
    
    
    color: rgba(0, 0, 0, 0.5);
}

.file_open:hover{
    border-radius: 5px;
    background-color: rgba(68, 68, 176, 0.95);
    color: rgba(255, 253, 253, 0.85);
    transition: 0.5s ease-out;
    
}

.textHelper{
    text-align: left;
    font-size: 12px;
    padding-left: 30px;
    color: 'white';
    font-family:  Arial, Helvetica, sans-serif;
    letter-spacing: 1px;


}
